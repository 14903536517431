import {BASE_URL,PRIVACY_POLICY} from '@env'
export default {
    BASE_URL:BASE_URL,
    // BASE_URL:'https://dtc-dev-api-may24.sofmen.com/api/',
    // BASE_URL:'https://dtc-dev.sofmen.com/api/',
    // BASE_URL:'https://6ac4-115-246-62-59.ngrok-free.app/api/',
    // BASE_URL:'https://dtc-dev-api.sofmen.com/api/',
    // BASE_URL:'https://64.62.249.172/api/',
    // BASE_URL:'https://dtc.qa.sofmen.com/api/',
    PRIVACY_POLICY:PRIVACY_POLICY,
    SIGNAL_R:"https://dtcapi.daytraderchallenge.com/leaderboardhub",
    APP_STORE_URL:'https://apps.apple.com/us/app/day-trader-challenge-free/id6476551646',
    PLAY_STORE_URL: 'https://play.google.com/store/apps/details?id=com.daytraderchallenge.daytrader'
};
